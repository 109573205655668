import { makeSign } from './encrypt'

const requestParamHandle = function(params) {

    let defaultData = {
        tm: Date.now(),
        ver: 'v1',
        gid: 8006,
    }

    // let customize_img_base64
    // let img_web_base64
    // let img_mob_base64
    // let img_pad_base64

    if (params) {
        const sign = params.sign || 'W:hP*w9f$We1496cUQ:$';
        /* if (params.customize_img_base64) {
             customize_img_base64 = params.customize_img_base64
             delete params.customize_img_base64
         }
         if (params.img_web_base64) {
             img_web_base64 = params.img_web_base64
             delete params.img_web_base64
         }
         if (params.img_mob_base64) {
             img_mob_base64 = params.img_mob_base64
             delete params.img_mob_base64
         }
         if (params.img_pad_base64) {
             img_pad_base64 = params.img_pad_base64
             delete params.img_pad_base64
         }*/
        Object.assign(defaultData, params)
        defaultData.sign = makeSign(defaultData, sign)
    }

    /* if (customize_img_base64) {
         defaultData.customize_img_base64 = customize_img_base64
     }
     if (img_web_base64) {
         defaultData.img_web_base64 =  img_web_base64
     }
     if (img_mob_base64) {
         defaultData.img_mob_base64 =  img_mob_base64
     }
     if (img_pad_base64) {
         defaultData.img_pad_base64 =  img_pad_base64
     }*/
    return defaultData
}

export default requestParamHandle