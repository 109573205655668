import request from '../utils/request';


function getChildList(data) {
    return request({
        url: '/categories',
        data
    })
}

function getNewest(data) {
    return request({
        url: '/newest',
        data
    })
}

function getArticleList(data) {
    return request({
        url: '/postslist',
        data
    })
}

function getArticle(data) {
    return request({
        url: '/posts',
        data
    })
}

function getVideoList(data) {
    return request({
        url: '/video',
        data
    })
}

function getImgList(data) {
    return request({
        url: '/gallery',
        data
    })
}


function getSwiperList(data) {
    return request({
        url: '/slide',
        data
    })
}

export {
    getChildList,
    getNewest,
    getArticle,
    getArticleList,
    getVideoList,
    getImgList,
    getSwiperList
}