import axios from 'axios'
import requestParamHandle from './request-param-handle'
import { Message } from 'element-ui'
import showRequestErrmsg from './show-request-errmsg'

// create an axios instance
const service = axios.create({
    // baseURL: '/website/web/v1',
    baseURL: 'https://api4jp.restargames.com/website/web/v1',
    timeout: 5000, // request timeout
    method: 'post'
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        const key = config.method === 'get' ? 'params' : 'data'
        config[key] = requestParamHandle(config[key])
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        Message({
            message: error.message,
            type: 'error'
        })
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const data = response.data
        const { code, message } = data

        // if the custom code is not 20000, it is judged as an error.
        if (code !== 1) {
            showRequestErrmsg(response.config.url, code, message)

            return Promise.reject(data)
        } else {
            return data
        }
    },
    error => {
        // console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error'
        })
        return Promise.reject(error)
    }
)

export default service;