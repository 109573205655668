import { Message } from 'element-ui'

const messages = {

}

export default function (url, code, message) {
    const isNoShowMsg = url === '/ck' || code === -1036
    
    if (isNoShowMsg) return
    
    Message({
        message: messages[code] || message || 'error',
        type: 'error'
    })
}